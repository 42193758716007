exports.stagesList = [
  {
  "code": 1,
  "title": "Completed"
 },
 {
  "code": 2,
  "title": "Ongoing"
 },
 {
  "code": 3,
  "title": "Abandoned"
 },
 {
  "code": 4,
  "title": "Not Started"
 },
]

exports.schoolCategoryList = [
  {
  "title": "ECCDE"
 },
 {
  "title": "Primary"
 },
 {
  "title": "JSS"
 }
]
   exports.YearList = [
       {
       "code": 2015
      },
      {
        "code": 2016
       },
       {
        "code": 2017
       },
       {
        "code": 2018
       },
       {
        "code": 2019
       },
       {
        "code": 2020
       },
       {
        "code": 2021
       },
       {
        "code": 2022
       },
       {
        "code": 2023
       },
       {
        "code": 2024
       },
       {
        "code": 2025
       },
       {
        "code": 2026
       },
       {
        "code": 2027
       },
       {
        "code": 2028
       },
       {
        "code": 2029
       },
       {
        "code": 2030
       },
       {
        "code": 2031
       },
       {
        "code": 2032
       },
       {
        "code": 2033
       },
       {
        "code": 2034
       },
       {
        "code": 2035
       },
       {
        "code": 2036
       },
       {
        "code": 2037
       },
       {
        "code": 2038
       },
       {
        "code": 2039
       },
       {
        "code": 2040
       },      

]



exports.LGEASList = [
    {
    "code": 1,
    "title": "Alimosho"
   },
   {
     "code": 2,
     "title": "Epe"
    },
    {
     "code": 3,
     "title": "Ikorodu"
    },
    {
     "code": 4,
     "title": "Oshodi-Isolo"
    },
    {
        "code": 5,
        "title": "Lagos Mainland"
       },
       {
        "code": 6,
        "title": "Shomolu"
       },
       {
        "code": 7,
        "title": "Badagry"
       },
       {
        "code": 8,
        "title": "Ifako-Ijaye"
       },
       {
        "code": 9,
        "title": "Ikeja"
       },
       {
        "code": 10,
        "title": "Agege"
       },
       {
        "code": 11,
        "title": "Ajeromi-Ifelodun"
       },
       {
        "code": 12,
        "title": "Apapa"
       },
       {
        "code": 13,
        "title": "Ojo"
       },
       {
        "code": 14,
        "title": "Mushin"
       },
       {
        "code": 15,
        "title": "Amuwo-Odofin"
       },
       {
        "code": 16,
        "title": "Ibeju-Lekki"
       },
       {
        "code": 17,
        "title": "Surulere"
       },
]

exports.ProjectsList = [
      {
      "code": 1,
      "title": "Building"
    },
    {
      "code": 2,
      "title": "Fencing"
      },
      {
      "code": 3,
      "title": "Rehabilitation"
      },
      {
      "code": 4,
      "title": "Furniture and Equipment"
      },
      {
          "code": 5,
          "title": "Water Supply"
        },
        {
          "code": 6,
          "title": "Agricultural Education"
        },
       {
        "code": 7,
        "title": "Sport Development"
       },
       {
        "code": 8,
        "title": "Quality Assurance"
       },
       {
        "code": 9,
        "title": "Project Monitoring"
       },


]
   
