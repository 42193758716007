<template>
    <div>
   <MobileTopbar/>
  <div class="dashboard-row">

 <SideBar/>
    <div class="dashboard-col-2">
      <div class="content-heading">
        <div class="div-block-4">
          <div class="text-block-3">Settings</div>
         
        </div>
        <div><img src="images/back.svg" loading="lazy" alt="" class="image-4"></div>
      </div>
      <div class="app-card">
        <div class="content-tab">
          <div class="tab-title" :class="[ profile ? activeTabClass : '']" @click="openProfile">UPDATE PROFILE</div>
          <div v-show="role == 0 || role == 2 || role == 3 || role == 4" class="tab-title" :class="[ upload ? activeTabClass : '']" @click="openUpload">ACTION-PLAN UPLOAD</div>
          <div v-show="role == 0 || role == 2 || role == 3 || role == 4" class="tab-title" :class="[ upload_table ? activeTabClass : '']" @click="openUploadTable">ACTION-PLAN HISTORY</div>
          <div v-show="role == 0 || role == 2 || role == 3 || role == 4" class="tab-title" :class="[ report ? activeTabClass : '']" @click="openReport">REPORT UPLOAD</div>
          <div v-show="role == 0 || role == 2 || role == 3 || role == 4" class="tab-title" :class="[ report_table ? activeTabClass : '']" @click="openReportTable">REPORT HISTORY</div>
           <div v-show="role == 2 || role == 4" class="tab-title" :class="[ publish ? activeTabClass : '']" @click="openPublish">PUBLISH</div>
        </div>
      <Profile v-show="profile"/>
      <Upload v-show="upload"/>
      <UploadTable v-show="upload_table"/>
        <Report v-show="report"/>
      <Publish v-show="publish"/>
      <report-upload-table v-show="report_table"/>
    </div>
    
  </div>
    </div>
    </div>
</template>

<script>
import MobileTopbar from "../../../../components/Dashboard/MobileTopBar.vue"
import SideBar from "../../../../components/Dashboard/SideBar.vue"
import Profile from "./Profile.vue"
import Upload from "./Upload.vue"
import UploadTable from "./UploadTable.vue"
import Publish from "./Publish.vue"
import Report from "./ReportUpload.vue"
import ReportUploadTable from './ReportUploadTable.vue'

export default {
    components: {
      MobileTopbar,
      SideBar,
      Profile,
      Upload,
      UploadTable,
      Publish,
      Report,
        ReportUploadTable
    },
        data(){
    return{
       role: JSON.parse(localStorage.getItem('role')),
      activeTabClass: 'active-tab',
      profile: true,
      upload: false,
      upload_table: false,
      publish: false,
      report: false,
      report_table:false
    }   
    },
       methods:{

     openProfile(){
     this.profile = true;
     this.upload = false;
     this.upload_table = false;
     this.publish = false;
     this.report = false;
     this.report_table = false;
      },

     openUpload(){
     this.profile = false;
     this.upload = true;
     this.upload_table = false;
     this.publish = false;
     this.report = false;
     this.report_table = false;
      },

     openUploadTable(){
     this.profile = false;
     this.upload = false;
     this.upload_table = true;
     this.publish = false;
     this.report = false;
     this.report_table = false;
      },

     openPublish(){
     this.profile = false;
     this.upload = false;
     this.upload_table = false;
     this.publish = true;
     this.report = false;
     this.report_table = false;
      }, 

     openReport(){
     this.profile = false;
     this.upload = false;
     this.upload_table = false;
     this.publish = false;
     this.report = true;
     this.report_table = false;    
      },

      
     openReportTable(){
     this.profile = false;
     this.upload = false;
     this.upload_table = false;
     this.publish = false;
     this.report = false;
     this.report_table = true;    
      },

       }
}
</script>

