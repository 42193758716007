var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"app-card"},[_c('notifications'),_c('Loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}]}),_c('div',{staticClass:"card-heading"},[_vm._m(0),_c('div',[_c('div',{staticClass:"form-block w-form"},[_c('button',{staticClass:"app-btn primary",on:{"click":_vm.getAllReport}},[_vm._v("Reset Action Plan")])])])]),_vm._m(1),_c('div',{staticClass:"graph-row"},[_c('div',{staticClass:"histogram"},[_c('apexchart',{attrs:{"type":"bar","height":"350","options":_vm.bar_chartOptions,"series":_vm.bar_series}})],1),_c('div',{staticClass:"pie-chart"},[_c('div',{staticClass:"text-block-10"},[_vm._v("Y"+_vm._s(_vm.year)+" Analytics")]),_c('apexchart',{attrs:{"type":"pie","width":"300","options":_vm.pie_chartOptions,"series":_vm.pie_series}})],1)]),_c('div',{staticClass:"project-detail-row"},_vm._l((_vm.actionReport),function(item,index){return _c('div',{key:index,staticClass:"prject-detail-col"},[_c('div',{staticClass:"detail-header",style:({color: index == 0 ? 'rgba(0, 143, 251, 0.85)' : 
                                                       index == 1 ? 'rgba(0, 227, 150, 0.85)' : 
                                                       index == 2 ? 'rgba(254, 176, 25, 0.85)' : 
                                                       index == 3 ? 'rgba(255, 69, 96, 0.85)' : 
                                                       index == 4 ? 'rgba(119, 93, 208, 0.85)' : 
                                                       index == 5 ? 'rgba(0, 143, 251, 0.85)' : 
                                                       index == 6 ? 'rgba(0, 227, 150, 0.85)' : 
                                                       index == 7 ? 'rgba(254, 176, 25, 0.85)' : 
                                                       index == 8 ? 'rgba(255, 69, 96, 0.85)' : 'rgba(0, 143, 251, 0.85)'})},[_vm._v(_vm._s(item.area_of_intervention))]),_c('div',{staticClass:"div-block-3"},[_c('div',{staticClass:"text-block-13"},[_vm._v("No of Projects")]),_c('div',{staticClass:"text-block-12"},[_vm._v(_vm._s(item.no_of_projects))])]),_c('div',{staticClass:"div-block-3"},[_c('div',{staticClass:"text-block-13"},[_vm._v("Amount")]),_c('div',{staticClass:"text-block-12"},[_vm._v("N"+_vm._s(item.actual_amount.toLocaleString()))])])])}),0)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"text-block-9"},[_vm._v("Breakdown Of Funds Into Programme Components")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"projects-indicator-row"},[_c('div',{staticClass:"project-indicator"},[_c('div',{staticClass:"indicator-box"}),_c('div',{staticClass:"indicator-text"},[_vm._v("(A) Building")])]),_c('div',{staticClass:"project-indicator"},[_c('div',{staticClass:"indicator-box _2"}),_c('div',{staticClass:"indicator-text"},[_vm._v("(B) Fencing")])]),_c('div',{staticClass:"project-indicator"},[_c('div',{staticClass:"indicator-box _3"}),_c('div',{staticClass:"indicator-text"},[_vm._v("(C) Rehabilitation")])]),_c('div',{staticClass:"project-indicator"},[_c('div',{staticClass:"indicator-box _4"}),_c('div',{staticClass:"indicator-text"},[_vm._v("(D) Furniture and Equipment")])]),_c('div',{staticClass:"project-indicator"},[_c('div',{staticClass:"indicator-box _5"}),_c('div',{staticClass:"indicator-text"},[_vm._v("(E) Water Supply")])]),_c('div',{staticClass:"project-indicator"},[_c('div',{staticClass:"indicator-box _6"}),_c('div',{staticClass:"indicator-text"},[_vm._v("(F) Agricultural Education")])]),_c('div',{staticClass:"project-indicator"},[_c('div',{staticClass:"indicator-box _7"}),_c('div',{staticClass:"indicator-text"},[_vm._v("(G) Sport Development")])]),_c('div',{staticClass:"project-indicator"},[_c('div',{staticClass:"indicator-box _8"}),_c('div',{staticClass:"indicator-text"},[_vm._v("(H) Quality Assurance")])]),_c('div',{staticClass:"project-indicator"},[_c('div',{staticClass:"indicator-box _9"}),_c('div',{staticClass:"indicator-text"},[_vm._v("(I) Project Monitoring")])])])
}]

export { render, staticRenderFns }